


#home {
  scroll-margin-top: 100px;  /* Adjusted considering your body padding */
}

#featured {
  scroll-margin-top: 100px;  /* Adjusted considering your body padding */
}

h1 {
  font-family: 'PT Sans', sans-serif;
}
h2 {
  font-family: 'PT Sans', sans-serif;
}
h3 {
  font-family: 'PT Sans', sans-serif;
}
.table td span.table-dark-red { background-color: hsl(0, 100%, 40%); color: #fff; padding: 0.2em; }
.table td span.table-orange { background-color: hsl(30, 100%, 50%); color: #fff; padding: 0.2em; }
.table td span.table-yellow { background-color: hsl(60, 100%, 50%); padding: 0.2em; }
.table td span.table-light-green { background-color: hsl(100, 70%, 75%); padding: 0.2em; }
.table td span.table-green { background-color: hsl(120, 100%, 50%); padding: 0.2em; }


.grey-background {
  background-color: rgb(235, 235, 235) !important;
}

.dark-grey-background {
  background-color: rgb(195, 195, 195) !important;
}

.green-background {
  background-color: rgb(201, 204, 162) !important;
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
