

.settings-container {
    display: flex;
    justify-content: center;
    padding: 80px;
    /*align-items: center;*/
    /*min-height: 100vh; !* Use the full height of the viewport *!*/
    margin: 0;
    /*padding: 20px; !* Add some padding *!*/
}

.settings-box {
    width: 100%;
    max-width: 500px; /* Set the maximum width of the box */
    background-color: #fff; /* Set background color to white */
    padding: 20px;
    border-radius: 5px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

/*.custom-tabs .nav-link {*/
/*    color: #yourDesiredColor;*/
/*    background-color: #backgroundForInactiveTab; !* Background color for inactive tabs *!*/
/*}*/

/*.custom-tabs .nav-link.active {*/
/*    color: #yourDesiredColorForActiveTab;*/
/*    background-color: #backgroundForActiveTab; !* Background color for active tab *!*/
/*}*/

/*.custom-tabs .nav-link:hover {*/
/*    color: #colorOnHover; !* Text color when hovering *!*/
/*    background-color: #backgroundOnHover; !* Background color when hovering *!*/
/*}*/
